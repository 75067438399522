import { axiosInstance as axios } from 'helpers/axiosInstance';
import logger from 'helpers/logger';
import PagesManifestPlugin from 'next/dist/build/webpack/plugins/pages-manifest-plugin';
import { queryCmsContent } from './_api';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
// import { NEXT_PUBLIC_API_URL, NEXT_PUBLIC_LOCAL_HOSTNAME_URL } from './_constants';

//CMS is not exposed to the website, therefore we fetch the news via a Next.JS API
export const getNewsFromApi = async (
	limit: number,
	offset: number,
	searchableLocations: string[],
	searchableTopics: string[],
	preview: boolean,
	previewData: Models.PreviewData,
	locale?: string,
): Promise<Models.NewsArticle[]> => {
	return axios({
		url: `/api/news`,
		method: 'POST',
		headers: {},
		data: {
			limit,
			offset,
			searchableLocations,
			searchableTopics,
			preview,
			previewData,
		},
	})
		.then((res) => {
			const articles: Models.NewsArticle[] = res.data?.newsArticles;
			return articles;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`News retrieval failed: ${message}`);
			throw message;
		});
};

export const getNewsFromCms = async (
	host: string,
	limit: number,
	offset: number,
	searchableLocations: string[],
	searchableTopics: string[],
	preview: boolean,
	previewData: Models.PreviewData,
	locale: string,
): Promise<Models.NewsArticle[]> => {
	
	var locationFilterString = "";
	if (Array.isArray(searchableLocations) && searchableLocations.length) {
	 	locationFilterString = '{alias: "searchableLocations", values: ["' + searchableLocations.join('","') + '"]},';
	}

	var topicFilterString = "";
	if (Array.isArray(searchableTopics) && searchableTopics.length) {
		topicFilterString = '{alias: "searchableTopics", values: ["' + searchableTopics.join('","') + '"]},';
	}

	const graphQLquery = `{
		pageSearch(documentType:["newsPage"] limit:${limit} offset:${offset},
			filter: [
				${locationFilterString}
				${topicFilterString}
			]
		)
			{
				pages {
					name
					url
					id
					properties
				}
			}
		}`;

	const profiler = logger.startTimer();

	try {
		const results = await queryCmsContent({
			host,
			query: graphQLquery,
			preview,
			previewData,
			method: 'POST',
			locale,
		});

		const articles = results.pageSearch?.pages.map((page) => ({
			heading: page.properties.newsHeading,
			url: page.url,
			kicker: page.properties.newsKicker,
			text: page.properties.newsTeaser,
			date: new Date(page.properties.newsDate),
		}));

		return articles;
	} finally {
		profiler.done({
			message: 'getNewsFromCms',
			level: 'debug',
		});
	}
};

export const getSortedNewsFromCmsNew = async (params:any): Promise<Models.NewsList> => {
	const { limit, offset, searchableLocations, searchableTopics, locale } = params;
	const pageSize = limit || 10;
	const skip = (offset || 0);
	const locationFilterString = searchableLocations?.length ? `{alias: "searchableLocations", values: ["${searchableLocations.join('","')}"]},` : "";
	const topicFilterString = searchableTopics?.length ? `{alias: "searchableTopics", values: ["${searchableTopics.join('","')}"]},` : "";
		const query = `{
			pageSearch(documentType:["newsPage"] limit:${pageSize} offset:${skip} orderDesc:"newsDate",orderType:"Long",
				filter: [
					${locationFilterString}
					${topicFilterString}
				]
			)
				{
					totalCount,
					limit,
					offset,
					pages {
						name
						url
						id
						properties
					},
				}
			}`;
	return axios({
		url: `${publicRuntimeConfig.NEXT_PUBLIC_API_URL}`,
		method: 'POST',
		data: JSON.stringify({ query }),
		headers: {
			'Content-Culure': locale || 'da',
			'Content-Type': 'application/json',
			hostname: `${window.location.origin}`,
		},
	})
		.then((res) => {
			const newsRes = res.data.data?.pageSearch;
			const mappedData: Models.NewsList = {
				pageNumber: offset,
				pageSize: pageSize,
				totalPages: Math.ceil(newsRes.totalCount / pageSize),
				news: newsRes.pages,
			};
			return mappedData;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`events retrieval failed: ${message}`);
			throw message;
		});
};
