import { Accordion } from 'ui/components';

export const AccordionFeature: React.FC<Content.Accordion> = ({ content }) => {
	const { accordionItems } = content?.properties ?? {};

	return (
		<Accordion
			{...content?.properties}
			allowMultiple={false}
			accordionItems={accordionItems.map(({ content, settings }) => {
				const { htmlContent, title, anchor } = content?.properties ?? {};
				const { openByDefault } = settings?.properties ?? {};

				return {
					htmlContent,
					title,
					anchor,
					openByDefault,
				};
			})}
		/>
	);
};
