import { Linkbox } from 'ui/components';

export const LinkboxFeature: React.FC<Content.Linkbox> = ({ content }) => {
	const { heading, linkboxItems, backgroundColor } = content?.properties ?? {};

	return (
		<Linkbox
			heading={heading}
			linkboxItems={linkboxItems.map(({ content }) => {
				const { text, link } = content?.properties ?? {};

				return {
					text,
					link,
				};
			})}
			backgroundColor={backgroundColor}
		/>
	);
};
