import { ContactBox } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';

export const ContactBoxFeature: React.FC<Content.ContactBox> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const siteContext = useSiteContext();
	const sundhedsplatformTekstSiteSetting = siteContext.sundhedsplatformTekstSiteSetting;

	const { heading, manchet, contactCard} = content?.properties ?? {};

	const address =  dictionary.getValue('Contact.address', null, 'Adresse');
	const writeUs =  dictionary.getValue('Contact.writeUs', null, 'Skriv til os');
	const callUs =  dictionary.getValue('Contact.callUs', null, 'Ring til os');
	const openingHours =  dictionary.getValue('Contact.openingHours', null, 'Åbningstider');
	const visitingHours =  dictionary.getValue('Contact.visitingHours', null, 'Besøgstider');
	const otherInformationLabel =  dictionary.getValue('Contact.otherInformation', null, 'Øvrig information');
	const digitalMail =  dictionary.getValue('Contact.DigitalMail', null, 'Digital post');
	const seeMap =  dictionary.getValue('Contact.SeeMap', null, 'Se poost');
	return (
		<ContactBox
			heading={heading}
			manchet={manchet}
			contactCard={contactCard?.properties}
			sundhedsplatformTekstSiteSetting={sundhedsplatformTekstSiteSetting}
			address={address}
			writeUs={writeUs}
			callUs={callUs}
			openingHours={openingHours}
			visitingHours={visitingHours}
			otherInformationLabel={otherInformationLabel}
			digitalMail={digitalMail}
			seeMap={seeMap}
		/>
	);
};