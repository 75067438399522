import { axiosInstance as axios } from 'helpers/axiosInstance';
import { locale } from 'faker';
import logger from 'helpers/logger';
import { env } from 'process';
import getConfig from 'next/config';
const {publicRuntimeConfig} = getConfig()

//CMS is not exposed to the website, therefore we fetch the news via a Next.JS API
export const getAgendasFromApi = async (
	pageNumber:number,
	pageSize:number,
	types?:string[],
	locale?:string,

): Promise<Models.AgendaList> => {
	return axios({
		url: publicRuntimeConfig.NEXT_PUBLIC_AGENDA_API_URL+"/api/v1/Agenda/GetList",
		method: 'POST',
		headers: {
		"Content-Culture":locale ||'da',
        "Content-Type": "application/json"
		},
		params:{pageNumber:pageNumber, pageSize: pageSize},
		data: types || [],
	})
		.then((res) => {
			const agendaList: Models.AgendaList = res.data;
			return agendaList;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`Agenda retrieval failed: ${message}`);
			throw message;
		});
};

export const getAgendaByIdFromApi = async (
	guid:string,
	locale?:string,

): Promise<Models.Agenda> => {

	return axios({
		url: (publicRuntimeConfig.NEXT_PUBLIC_AGENDA_API_URL)+"/api/v1/Agenda/Get/"+guid,
		method: 'GET',
		headers: {
		"Content-Culture":locale ||'da',
        "Content-Type": "application/json"
		},
		
	})
		.then((res) => {
			const agenda: Models.Agenda = res.data;
			return agenda;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`Agenda retrieval failed: ${message}`);
			throw message;
		});
};