import { Main, Container, ArticleHeader, ArticleDate } from 'ui/components';
import { ModuleFeature } from 'features/Modules';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { IsLaterThanNumberOfDays } from 'helpers/timeManipulation'

export const NewsPageFeature: React.FC<Content.NewsPage> = ({ properties }) => {
	const { newsTeaser, newsKicker, newsHeading, newsDate, pageSections } = properties ?? {};
 	const dictionary = useDictionaryContext();
	let dateObj = new Date(newsDate);
	let formattetDate =  `${dateObj.toLocaleDateString(dictionary.culture, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}`;
	return (
		<Main>
			<Container width="Standard">
				<ArticleHeader teaser={newsTeaser} heading={newsHeading} kicker={newsKicker} />
				<ArticleDate dateAndText={formattetDate} old={IsLaterThanNumberOfDays(dateObj, 90)} />
			</Container>
			{pageSections.map((pageSection, index) => (
				<ModuleFeature key={index} contentModule={pageSection} />
			))}
		</Main>
	);
};
