import Head from 'next/head';
import { OpenGraph } from './OpenGraph';
import { GoogleTagManager } from './GoogleTagManager';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { usePageContext } from 'application/contexts/PageContext';
export const Meta: React.FC<Content.PageContent['page']['properties']> = ({
	title,
	noFollow,
	noIndex,
	description,
	location,
	tags,
	diagnoseCodes,
	themeColor,
}) => {
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const language = useDictionaryContext();
	const openGraph = pageContext?.getSettings('openGraph');
	const tagManager = siteContext?.settings?.tagManager;
	const siteTitle = siteContext?.title;
	const tagsString = tags.join(', ');
	const diagnoseCodesString = diagnoseCodes.join(', ');

	return (
		<>
			<Head>
				<title>
					{title ? title : pageContext.name} {siteTitle ? ' - ' + siteTitle : ''}
				</title>
				<meta name="themecolor" content={themeColor} />
				<meta name="robots" content={`${noFollow ? 'nofollow' : 'follow'}, ${noIndex ? 'noindex' : 'index'}`} />
				<meta name="description" content={description} />
				{location ? <meta name="location" content={location?.properties?.city} /> : ''}
				{diagnoseCodesString ? <meta name="diagnoseCodes" content={diagnoseCodesString} /> : ''}
				{tagsString ? <meta name="tags" content={tagsString} /> : ''}
			</Head>
			{openGraph && <OpenGraph {...openGraph} pageName={pageContext.name} pageUrl={pageContext.url} />}
			{tagManager && <GoogleTagManager {...tagManager} />}
		</>
	);
};
