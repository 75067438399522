import { Main } from 'ui/components';
import { ModuleFeature } from 'features/Modules';

export const EventsPageFeature: React.FC<Content.EventsPage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};
	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<ModuleFeature key={index} contentModule={headerModule} />
			))}
		>
			{pageSections.map((sectionModule, index) => (
				<ModuleFeature key={index} contentModule={sectionModule} />
			))}
		</Main>
	);
};
