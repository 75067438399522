import { NEXT_PUBLIC_COOKIEBOT_ID } from 'application/adapters/cms/_constants';
import { useLocaleContext } from 'application/contexts/LocaleContext';
import Script from 'next/script';

export const CookieConsent: React.FC = () => {
    const locale = useLocaleContext();
	return (
		<Script
          id="Cookiebot"
          src='https://consent.cookiebot.com/uc.js'
          data-cbid={NEXT_PUBLIC_COOKIEBOT_ID || 'f8a047db-0e63-4daa-9ff5-426ad46e233b'}
          data-blockingmode='auto'
          type='text/javascript'
          strategy='afterInteractive'
		  data-culture={locale.currentLocale}
          async
        >   
		</Script>
	);
};
