import { ListCardProps, Teaser } from 'ui/components';
import { usePageContext } from 'application/contexts/PageContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { formatTime } from 'helpers/date';

export const TeaserFeature: React.FC<Content.Teaser> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const pageContext = usePageContext();
	const listCards: ListCardProps[] = content?.properties?.teasers?.map((teaserCard) => {

		let dt = new Date(teaserCard?.properties?.eventDate);
		const translate = useDictionaryContext();

		let startDateTime = new Date(teaserCard?.properties?.startTime);
		let endDateTime = new Date(teaserCard?.properties?.endTime);
		const startTimeFormatted = formatTime(startDateTime, 'hh:mm');
		const endTimeFormatted = formatTime(endDateTime, 'hh:mm');
		
		return {
			event: {
				title: teaserCard?.properties?.heading,
				date: teaserCard?.properties?.eventDate,
				dateFormatted: `${dt?.toLocaleString(translate.culture, { dateStyle: 'long' })}`,
				startTime: startTimeFormatted,
				endTime: endTimeFormatted,
				location: teaserCard?.properties?.eventLocation,
				price: teaserCard?.properties?.price,
				month: teaserCard?.properties?.eventDate,
				url: teaserCard?.url,
			},
		};
	});
	return <Teaser {...content?.properties} listCards={listCards} />;
};
