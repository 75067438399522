import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Search } from 'ui/components';

export const SearchFeature: React.FC = () => {
	const dictionary = useDictionaryContext();

	return (
		<Search
			searchButtonText={dictionary.getValue('Search.Search', null, 'Søg')}
			searchInputPlaceholder={dictionary.getValue('Search.EnterKeyword', null, 'Indtast din søgning her')}
			requiredFieldErrorMessage={dictionary.getValue('Search.RequiredField', null, 'Feltet er påkrævet')}
			searchResultText={dictionary.getValue('Search.Results', null, 'Resultater')}
			searchErrorText={dictionary.getValue('Search.ErrorText', [], 'Der er opstået en fejl i søgningen. Prøv venligst')}
		/>
	);
};
