import { MapLocations } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
export const MapLocationsFeature: React.FC<Content.MapLocations> = ({ settings, content }) => {
	const dictionary = useDictionaryContext();
	const { mapLocationImage, location, heading } = content.properties ?? {};

	// const createRandomItem = () => {
	// 	const item = {
	// 		heading: faker.company.companyName(),
	// 		locationIcon: {
	// 			backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
	// 			text: 'C1',
	// 			textColor: 'white',
	// 		},
	// 		locationInformation: { street: 'Gl. Ringstedvej 2', intrance: 'Stuen Indgang C', trafficSpace: 'Trafikplads Lilla' },
	// 	};
	// 	return item;
	// };
	// const letters = [
	// 	'a',
	// 	'b',
	// 	'c',
	// 	'd',
	// 	'e',
	// 	'f',
	// 	'g',
	// 	'i',
	// 	'j',
	// 	'k',
	// 	'l',
	// 	'm',
	// 	'n',
	// 	'o',
	// 	'p',
	// 	'q',
	// 	'r',
	// 	's',
	// 	't',
	// 	'u',
	// 	'v',
	// 	'x',
	// 	'y',
	// 	'z',
	// 	'æ',
	// 	'ø',
	// 	'å',
	// ];
	// const _locations = letters.map((char) => {
	// 	return { character: char.toUpperCase(), list: Array(20).fill(createRandomItem()) };
	// });
	//const _s = new Set(_locations.map((loc) => loc.list[0].heading));
	return (
		<MapLocations
			{...content?.properties}
			heading={heading}
			location={location}
			//searchSuggestions={Array.from(_s)}
			//LetterSortedlocations={_locations}
			LetterFilterLinkTitle={dictionary.getValue('Search.FilterLetterTitle', null, 'Se alle resultater der starter med')}
			searchButtonText={dictionary.getValue('Search.Search', null, 'Søg')}
			resetButtonText={dictionary.getValue('Search.Reset', null, 'Nulstil')}
			printButtonText={dictionary.getValue('Action.Print', null, 'Print')}
			mapLocationImage={
				mapLocationImage
					? {
							url: mapLocationImage?.url,
							altText: mapLocationImage?.properties?.altText,
							focalPoint: mapLocationImage?.focalPoint,
					  }
					: null
			}
		/>
	);
};
