import { ArticleMedia } from 'ui/components';

export const ArticleMediaFeature: React.FC<Content.ArticleMedia> = ({ content }) => {

	return (
	<ArticleMedia
		caption={content?.properties?.caption}
		removeAlt={content?.properties?.removeAlt}
		image={{
			altText: content?.properties?.image?.properties?.altText,
			url: content?.properties?.image?.url,
			focalPoint: content?.properties?.image?.focalPoint,
		}}
		imageLink={content?.properties?.imageLink}
	/>
)};
