import { ContentCard } from 'ui/components';

export const ContentCardFeature: React.FC<Content.ContentCard> = ({ settings, content }) => {
	// const { displayLinkAsButton, orientation } = settings?.properties ?? {};

	return (
		<ContentCard
			{...content?.properties}
			// displayLinkAsButton={displayLinkAsButton}
			// orientation={orientation}
			image={
				content?.properties?.image
					? {
							url: content.properties.image.url,
							altText: content.properties.image.altText,
							focalPoint: content.properties.image.focalPoint,
					  }
					: null
			}
		/>
	);
};
