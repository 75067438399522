import classNames from 'classnames';
import { Heading, Container, Link } from 'ui/components';
import styles from './AgendaLinkbox.module.scss';
import { ReactComponent as ArrowRight } from '../../../icons/arrow-right.svg';

export interface AgendaLinkboxProps {
	className?: string;
	heading?: string;
	linkboxItems?: { text: string; link: Content.Link }[];
	backgroundColor?: string;
}

export const AgendaLinkbox: React.FC<AgendaLinkboxProps> = ({ className, heading, linkboxItems, backgroundColor = '#FFFFFF'}) => {
	return (
		<Container width="Standard" className={styles.Linkbox_container}>
			<div
				className={classNames(styles.Linkbox, className)}
				style={{ backgroundColor: backgroundColor ? backgroundColor : `var(--theme-20)` }}
			>
				<Heading headingLevel="h2" style="lg" className={classNames(styles.Linkbox_heading)}>
					{heading}
				</Heading>
				{linkboxItems && (
					<ul role="list" className={classNames(styles.Linkbox_linksContainer)}>
						{linkboxItems.map((linkboxItem, index) => (
							<li role="listitem" key={index} className={classNames(styles.Linkbox_links)}>
								<Link ariaLabel={linkboxItem?.text} url={linkboxItem.link?.url} className={classNames(styles.Linkbox_link)} target={linkboxItem.link?.target}>
									{linkboxItem?.text}
									<div className={styles.Linkbox_iconWrapper}>
										<ArrowRight className={styles.Linkbox_icon} />
									</div>
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		</Container>
	);
};
