import { ApiError } from 'helpers/apiError';
import { ApiRedirect } from 'helpers/apiRedirect';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import { CONTENT_API_URL, DOMAIN_PORT, DOMAIN_PROTOCOL } from './_constants';

export interface RequestInterface {
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	url?: string;
	accessToken?: string;
	query?: string;
	locale?:string;
	method: 'GET' | 'POST';
}

const buildHeaders = ({ domain, preview, previewData, accessToken, locale }): { [key: string]: string } => {
	const headers = {};
	headers['Content-Type'] = 'application/json';
	headers['Hostname'] = domain;
	headers['Content-Culture'] = locale || 'da';
	if (preview && typeof previewData === 'object') {
		headers['Authorization'] = previewData?.data?.auth;
	} else if (accessToken) {
		headers['Authorization'] = `Bearer ${accessToken}`;
	}

	return headers;
};

export const queryCmsContent = <TResponse = any>({
	host,
	preview,
	previewData,
	method,
	query,
	url,
	accessToken,
	locale
}: RequestInterface): Promise<TResponse> => {

	if (!CONTENT_API_URL) {
		throw new ApiError('CONTENT_API_URL:NOTDEFINED', 500, 'Content api url is not defined');
	}

	if (url === 'umbraco') {
		throw new ApiError('PageNotFound', 404, 'Page not found');
	}

	if (host === 'localhost' && process.env.FALLBACK_HOSTNAME) {
		host = process.env.FALLBACK_HOSTNAME;
	}

	const domain = `${DOMAIN_PROTOCOL ? `${DOMAIN_PROTOCOL}://` : ''}${host}${DOMAIN_PORT ? `:${DOMAIN_PORT}` : ''}/`;
	
	const headers = buildHeaders({ domain, preview, previewData, accessToken, locale });

	const contenturl = encodeURI(`${CONTENT_API_URL}${url ? url : ''}${preview ? '?preview=1' : ''}`);
	return axios({
		url: contenturl,
		method: method,
		maxRedirects: 0,
		headers,
		data: {
			query,
		},
	})
		.then((res) => {
			let result;

			if (method === 'POST') {
				const { data, errors } = res.data;

				if (errors) {
					throw new Error(errors[0].message);
				}
				result = data;

				result.preview = preview ? true : false;
				result.previewData = previewData ? previewData : null;
			}

			if (method === 'GET') {
				const { data } = res;
				result = data;
			}

			return result;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;
			
			if(statusCode === 301 || statusCode === 302 || statusCode === 307 ||statusCode === 308)
			{
				throw new ApiRedirect(statusCode,error?.response?.headers?.location)
			}

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}
			throw new ApiError('Unknown error', statusCode, message);
		});
};
