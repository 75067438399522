import { ApiError } from "./apiError";

export class ApiRedirect extends Error {
	public statusCode: number;
	public url: string;

	constructor(statusCode: number, url: string) {
		super();
		this.statusCode = statusCode;
		this.url = url;
	}
}

export const handleRedirects = (error: any): void => {
	const statusCode = error?.response?.status;

	if (statusCode === 301 || statusCode === 302) {
		const url = error?.response?.data?.url;
		if (url) {
			throw new ApiRedirect(statusCode, url);
		} else {
			throw new ApiError('Redirect', statusCode, 'Redirect without url');
		}
	}
};
