import { axiosInstance as axios } from 'helpers/axiosInstance';
import logger from 'helpers/logger';
import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig()

export const getSortedEventsFromCms = async (host?: string, limit?: number, offset?: number, locale?, searchableLocations?:string[], searchableTopics?:string[]): Promise<Models.EventList> => {
	const pageSize = limit || 10;
	const skip = offset * pageSize;
	const topics:string[] =searchableTopics.length > 0 ? searchableTopics : ["0"];
	const locations:string[] =searchableLocations.length>0 ? searchableLocations : ["0"];
	const padTo2Digits = (num: number) => num.toString().padStart(2, '0');
	
	const today =() => {
		var date = new Date();
		return (
		  [
			date.getFullYear(),
			padTo2Digits(date.getMonth() + 1),
			padTo2Digits(date.getDate()),
		  ].join('-') 
		);
	  }

	const query = `{
		pageSearch(documentType:["eventPage"]  limit:${pageSize || 10} offset:${skip || 0} orderAsc:"eventDate",orderType:"Long",
        range:[{alias: "eventDate", type: "DateTime", greaterThan: "${today()}"}],
		filter: [
            {alias: "searchableLocations", values:["${locations.join('","')}"]},
            {alias: "searchableTopics", values: ["${topics.join('","')}"]},
            ]
		)
            {
				totalCount,
                pages {
                    name
                    url
                    id
                    properties
                },
            }
		}`;
				
	return axios({
		url: `${publicRuntimeConfig.NEXT_PUBLIC_API_URL}`,
		method: 'POST',
		data: JSON.stringify({ query }),
		headers: {
			'Content-Culture':locale || 'da',
			'Content-Type': 'application/json',
			hostname: `${window.location.origin}`,
		},
	})
		.then((res) => {
			const eventRes = res.data.data?.pageSearch;
			const mappedData: Models.EventList = {
				pageNumber: offset,
				pageSize: pageSize,
				totalPages: Math.ceil(eventRes.totalCount / pageSize),
				events: eventRes.pages,
			};
			return mappedData;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`events retrieval failed: ${message}`);
			throw message;
		});
};