import { EmphasizedContentCard } from 'ui/components';

export const EmphasizedContentCardFeature: React.FC<Content.EmphasizedContentCard> = ({ settings, content }) => {
	const { displayLinkAsButton, orientation } = settings?.properties ?? {};

	return (
		<EmphasizedContentCard
			{...content?.properties}
			displayLinkAsButton={displayLinkAsButton}
			orientation={orientation}
			image={
				content?.properties?.image
					? {
							url: content.properties.image.url,
							altText: content.properties.image.properties.altText,
							focalPoint: content.properties.image.focalPoint,
					  }
					: null
			}
		/>
	);
};
