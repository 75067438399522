import { UndersideCard } from 'ui/components';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { PageContext } from 'application/contexts/PageContext';
import { useContext, useEffect, useState } from 'react';

export const UndersideCardFeature: React.FC<Content.UndersideCard> = ({ content }) => {
	const { heading } = content?.properties ?? {};

	const navContext = useNavigationContext();
	const pageContext = useContext(PageContext);

	const navigationItems = navContext?.getNavigation({ includeHidden: true });
	const pageUrl = pageContext.url;
	const navItems = [];

	const [isFrontPage, setIsFrontPage] = useState<boolean>(false);

	useEffect(() => {
		pageContext?.content?.pageSettings.map((item) => {
			if (item.documentType === 'isFrontPage') {
				setIsFrontPage(true);
			}
		});
	}, []);

	navigationItems.some((navigationItem) => {
		if (isFrontPage) {
			if (navigationItem?.url === heading?.url) {
				navigationItem.descendants?.map((item) => {
					navItems.push(item);
				});
				return true;
			}
			if (heading?.url === pageUrl) {
				navItems.push(navigationItem);
			}
		}
		return findMatch(navigationItem);
	});

	function findMatch(item: Navigation.NavigationItem) {
		if (item?.url === heading?.url) {
			item.descendants?.map((item) => {
				if (!item.hidden) {
					navItems.push(item);
				}
			});
			return true;
		}

		if (item.descendants) {
			return item.descendants.some((item) => {
				return findMatch(item);
			});
		}
		return false;
	}

	return <UndersideCard {...content?.properties} links={navItems} />;
};
