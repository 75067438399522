import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { getEventsByFilters } from 'application/repositories/eventRepository';
import useSWR from 'swr';
import { ListCard as EventCard, ListCardProps as EventCardProps } from 'ui/components';

export interface EventFetcherParams {
	pageNumber?: number;
	pageSize?: number;
	totalPages?: number;
	searchableLocations?: string[];
	searchableTopics?: string[];
}


const getEventsFromRepository = async (params:EventFetcherParams): Promise<Models.EventList> => {

	return await getEventsByFilters(params);
};

export interface EventFetcherResult {
	pageNumber?: number;
	pageSize?: number;
	totalPages?: number;
	events?: EventCardProps[];
	isLoading?: boolean;
	
	
}
interface IEventDataItem {
	id?: string;
	name?: string;
	url?: string;
	properties?: {
		heading: string;
		manchet: string;
		category: string;
		eventDate: string;
		startTime: string;
		endTime: string;
		eventLocation: string;
		price: string;
		richText: string;
	};
	heading: string;
	manchet: string;
	category: string;
	eventDate: string;
	eventTime: string;
	location?: string;
	price: string;
	richText: string;
}

export const eventsFetcher = (pageNumber?: number, pageSize?: number, searchableLocations?: string[], searchableTopics?: string[]): EventFetcherResult => {
	const context = usePageContext();
	const translate = useDictionaryContext();
	const { data, error } = useSWR({
		pageSize: pageSize,
		pageNumber: pageNumber,
		searchableLocations:searchableLocations,
		searchableTopics:searchableTopics, 
		preview:context.preview, 
		previewData:context.previewData
	}, getEventsFromRepository);
	
	
	if (error) throw error;
	if (!data) return { isLoading: true, events: [] };

	
	const eventCards: EventFetcherResult = {
		events: data.events.map((event: IEventDataItem) => {
			let dt = new Date(event.properties.eventDate);
			let dstr = `${dt.toLocaleString(translate.culture, { month: 'long' })} ${dt.getFullYear()}`;


			const eventCard: EventCardProps = {
				event: {
					date:`${dt.toLocaleString(translate.culture, { dateStyle: 'long' })}` ,
					dateFormatted: `${dt.toLocaleString(translate.culture, { dateStyle: 'long' })}` ,
					startTime: event.properties.startTime,
					endTime: event.properties.endTime,
					location:event.properties.eventLocation,
					price: event.properties.price,
					title: event.properties.heading,
					month: dstr,
					url: event.url,
				},
			};
		
		
			return eventCard;
		}),
		pageNumber: data.pageNumber,
		pageSize: data.pageSize,
		totalPages: data.totalPages,
		isLoading: false,
	};
	
	
	return eventCards;
	
	
};
