import { HeroCard } from 'ui/components';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { PageContext } from 'application/contexts/PageContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useContext, useEffect, useState } from 'react';

export const HeroCardFeature: React.FC<Content.HeroCard> = ({ content, settings }) => {
	const navContext = useNavigationContext();
	const pageContext = useContext(PageContext);

	const dictionary = useDictionaryContext();
    const anchorLinkText =  dictionary.getValue('Contact.AnchorLinkText', null, 'Se kontaktoplysninger');

	const navigationItems = navContext?.getNavigation({});
	const pageName = pageContext.name;

	const [isFirstLevelBreadcrumb, setIsFirstLevelBreadcrumb] = useState<boolean>(false);

	useEffect(() => {
		navigationItems.map((navigationItem) => {
			if (pageName === navigationItem.name) {
				setIsFirstLevelBreadcrumb(true);
			}
		});
	}, [pageContext.url]);

	const breadcrumbItems = navContext?.getBreadcrumb({ currentUrl: pageContext.url, includeRootItem: true });
	if (!breadcrumbItems) return null;

	return (
		<HeroCard
			{...content?.properties}
			{...settings?.properties}
			isFirstLevel={isFirstLevelBreadcrumb}
			anchorLinkText={anchorLinkText}
		/>
	);
};
