import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { getAllAgendaById, getAllAgendas, getAllAgendasByType } from 'application/repositories/AgendaRepository';
import useSWR from 'swr';
import { AgendaCardProps } from 'ui/components';

const getAgendasFromRepository = async (
	pageSize?: number,
	pageNumber?: number,
	types?: string[],
): Promise<Models.AgendaList> => {
	if(!types && types.length === 0){
	return await getAllAgendas({ pageNumber, pageSize});
	}else{
		return await getAllAgendasByType({ pageNumber, pageSize, types });
	}
};

export interface AgendaFetcherResult {
	pageNumber?: number;
	pageSize?: number;
	totalPages?:number;
	agendas?: AgendaCardProps[];
	types?:string[];
	isLoading?: boolean;
}
interface IAgendaDataItem {
	agendaUid?:string;
	committeeUid?:string;
	committeeName?:string | null;
	organisationUid?:string;
	name?:string;
	sourceId?:string;
	highLight?:string | null;
	meetingBeginFromUtc?:string;
	meetingEndUtc?:string;
	releasedDate?:string;
	minutesOfMeeting?:boolean;
	meetingLocation?:string;
	attendees?:[];
	items?:[];
	agendaType?:string;
	agendaTypeLabel?:string;
}
const getAgendaByIdFromRepository = async (
	guid?: string,
): Promise<Models.Agenda> => {
	return await getAllAgendaById({guid});

};
export const agendaFetcher =  (
	pageNumber?: number,
	pageSize?: number,
	agendaFilterOptions?: string[],
):AgendaFetcherResult =>  {
	const context = usePageContext();
	const translate = useDictionaryContext()
	const { data, error } = useSWR({
		pageNumber: pageNumber,
		pageSize: pageSize,
		agendaFilterOptions: agendaFilterOptions,
		preview: context.preview,
		previewData: context.previewData
	}, getAgendasFromRepository);
	if (error) throw error;
	if (!data) return { isLoading: true, agendas: [] };
	const AgendaCards:AgendaFetcherResult = {
		agendas:data.agendas.map((agenda:Models.Agenda)=>{
			let dt = new Date(agenda.releasedDate);
			let dstr = `${dt.getDay()} ${dt.toLocaleString(translate.culture,{month:'long'})}`;
			const agendaCard:AgendaCardProps ={
				agendaType:agenda.agendaType,
				text:agenda.agendaTypeLabel,
				heading:agenda.name,
				date: dstr,
				link:{
					url:agenda.agendaUid,
					name:agenda.agendaTypeLabel
				}

			}
			return agendaCard
		}),
		pageNumber:data.pageNumber,
		pageSize:data.pageSize,
		totalPages:(data.totalPages / data.pageSize)=== 0 ? 1 :(data.totalPages / data.pageSize),
		isLoading:false,
		types:data.types
	}
	return AgendaCards;
};
export const  agendaService = async (
	pageNumber?: number,
	pageSize?: number,
	agendaFilterOptions?: string[],
):Promise<AgendaFetcherResult | any> =>  {
	return await getAgendasFromRepository(pageNumber,pageSize,agendaFilterOptions).then((data)=>{
	if (!data) return { isLoading: true, agendas: [] };

	const AgendaCards:AgendaFetcherResult = {
		agendas:data.agendas.map((agenda:Models.Agenda)=>{
			let dt = new Date(agenda.releasedDate);
			let dstr = `${dt.getDay()} ${dt.toLocaleString('da-DK',{month:'long'})}`;
			const agendaCard:AgendaCardProps ={
				agendaType:agenda.agendaType,
				text:agenda.agendaTypeLabel,
				heading:agenda.name,
				date: dstr,
				link:{
					url:agenda.agendaUid,
					name:agenda.agendaTypeLabel
				}

			}
			return agendaCard
		}),
		pageNumber:data.pageNumber,
		pageSize:data.pageSize,
		totalPages:(data.totalPages / data.pageSize)=== 0 ? 1 :(data.totalPages / data.pageSize),
		isLoading:false
	}
	return AgendaCards;
}).catch((error)=>{
	if (error) throw error;
});
}