import { VideoTeaser } from 'ui/components';

export const VideoTeaserFeature: React.FC<Content.VideoTeaser> = ({ content }) => {

	return (
		<VideoTeaser
			{...content?.properties}
		/>
	);
};
