import { get } from 'env-var';
import logger from 'helpers/logger';

// let WEBSITE_SSG_URL: string;
// let ROBOTS_TXT_DISALLOW_ALL: boolean;
let LOG_TO_DISK: boolean;
let NEXT_PUBLIC_LOG_TO_CONSOLE: boolean;
let NODE_ENV: string;

// Only perform assignment and check serverside / at build time
if (typeof window === 'undefined') {
	// Read variables using env-var API
	// WEBSITE_SSG_URL = get('WEBSITE_SSG_URL').required().asUrlString();
	// ROBOTS_TXT_DISALLOW_ALL = get('ROBOTS_TXT_DISALLOW_ALL').default('false').asBool();
	LOG_TO_DISK = get('LOG_TO_DISK').required().asBoolStrict();
	NEXT_PUBLIC_LOG_TO_CONSOLE = get('NEXT_PUBLIC_LOG_TO_CONSOLE').required().asBoolStrict();
	NODE_ENV = get('NODE_ENV').required().asString();
} else {
	try {
		// Force NextJs to bake in public vars client side using direct reference to process.env
		// https://github.com/evanshortiss/env-var/issues/162
		NEXT_PUBLIC_LOG_TO_CONSOLE = Boolean(process.env.NEXT_PUBLIC_LOG_TO_CONSOLE);

		NODE_ENV = process.env.NODE_ENV;
		if (typeof NODE_ENV === 'undefined') {
			throw new Error(`NODE_ENV is undefined`);
		}
	} catch (e) {
		logger.error(e);
	}
}

export { LOG_TO_DISK, NEXT_PUBLIC_LOG_TO_CONSOLE, NODE_ENV };
