import { Meta } from './Meta';
import { HeaderFeature } from '../Header';
import { FooterFeature } from '../Footer';
import { CookieConsent } from './Meta/CookieConsent';
import { SiteImprove } from './Meta/SiteImprove';
import { SkipToMain } from 'ui/components';
import { Layout } from 'ui/components/4-habitats/Layout/Layout';
import { useRouter } from 'next/router';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const LayoutFeature: React.FC<Content.PageContent> = ({ children, page, siteTheme }) => {
	const { themeBase, theme50, theme30, theme20, theme10 } = siteTheme[0]?.content?.properties?.theme?.properties ?? {};
	const router = useRouter();
	const themeStyle = {
		'--theme-base': themeBase,
		'--theme-50': theme50,
		'--theme-30': theme30,
		'--theme-20': theme20,
		'--theme-10': theme10,
	} as React.CSSProperties;
	const dictionary = useDictionaryContext();

	return (
		<Layout router={router}>
			<SkipToMain
				link="#main"
				title={dictionary.getValue('SkipToMain.AriaLabel', null, 'SkipToMain.AriaLabel')}
				ariaLabel={dictionary.getValue('SkipToMain.AriaLabel', null, 'SkipToMain.AriaLabel')}
			>
				{dictionary.getValue('SkipToMain.AriaLabel', null, 'SkipToMain.AriaLabel')}
			</SkipToMain>
			<div style={themeStyle}>
				<CookieConsent />
				<SiteImprove />
				<Meta {...page?.properties} themeColor={themeBase} />
				<HeaderFeature />
				{children}
				<FooterFeature />
			</div>
		</Layout>
	);
};
