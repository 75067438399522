import { NewsTeaser } from 'ui/components';
import { usePageContext } from 'application/contexts/PageContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const NewsTeaserFeature: React.FC<Content.NewsTeaser> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const pageContext = usePageContext();
	const newsTeaserProps = pageContext._pageContent.page.properties.pageSections.find((item) => item.documentType === 'newsTeaser');
	const transformednewsTeaserData = newsTeaserProps.content.properties.newsTeaserList?.map((item) => {
		const { properties } = item;
		return {
			date: properties?.newsDate,
			kicker: properties?.newsHeading,
			text: properties?.newsTeaser,
			link: { name: properties?.newsHeading, url: item.url },
		};
	});

	return (
		<NewsTeaser
			{...content?.properties}
			link={newsTeaserProps.content.properties?.seeMoreLink}
			blurbCards={transformednewsTeaserData}
		/>
	);
};
