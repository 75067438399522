import { EventList } from 'ui/components';
import { eventsFetcher } from './EventListFetcher';
import { useEffect, useState } from 'react';
import { usePageContext } from 'application/contexts/PageContext';

export const EventListFeature: React.FC<Content.EventList> = ({content}) => {
	const locationFilter = content?.properties.locationFilter?.map(location => location.id) ?? [];
	const topicFilter = content?.properties.topicFilter?.map(topic => topic.id) ?? [];
	const pageContext = usePageContext();
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(content?.properties?.count);
	const [pageNumber,setPageNumber] = useState(0);
	const eventFetcher = eventsFetcher(pageNumber, content?.properties?.count ,locationFilter,topicFilter)
	const [totalPages, setTotalPages] = useState(eventFetcher.totalPages);
	useEffect(()=>{setTotalPages(eventFetcher.totalPages);},[eventFetcher.events])

	const onPageClickHandler = (event: {
		index: number | null;
		selected: number;
		nextSelectedPage: number | undefined;
		event: object;
		isPrevious: boolean;
		isNext: boolean;
		isBreak: boolean;
		isActive: boolean;
	  }) => {
		setPageNumber(event.selected);
		setOffset(event.selected * limit);
	};

	return(
		<EventList pageNumber={pageNumber} {...content?.properties} events={eventFetcher.events}  
		 totalPages={totalPages} setOffset={setOffset} onPageClickHandler={onPageClickHandler}  />
	)
	
};
