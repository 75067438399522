import { useNavigationContext } from 'application/contexts/NavigationContext';
import { Header, Navigation, ServiceNavigation, MobileNavigation, SubsiteBar, LanguageSelector } from 'ui/components';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { useState, useEffect } from 'react';
import NextLink from 'next/link';
import { useLocaleContext } from 'application/contexts/LocaleContext';
export const HeaderFeature: React.FC = () => {
	const navContext = useNavigationContext();
	const pageContext = usePageContext();
	const siteContext = useSiteContext();
	const { currentLocale } = useLocaleContext();
	const siteLogo = siteContext.logo;
	const siteLogoLink = siteContext.logoLink;
	const siteLogoSize = siteContext.logoSize;
	const pageLogo = pageContext._pageContent.page.properties?.logoPagesettings;
	//Change this
	const subsiteBarLogo = siteContext.subsiteBarLogo;
	const searchPage = siteContext.searchPage;
	const subsiteBarLogoLink = siteContext.subsiteBarLogoLink;
	const subsiteBar = siteContext.hasSubsiteBar;
	const hideServiceNavigation = siteContext.hideServiceNavigation;

	const navigationItems = navContext?.getNavigation({
		currentUrl: pageContext.url,
	});

	const serviceNavigationItems = navContext?.getNavigation({
		byName: 'servicemenu',
	});

	const [isFrontPage, setIsFrontPage] = useState<boolean>(false);
	useEffect(() => {
		setIsFrontPage(false);
		pageContext?.content?.pageSettings.map((item) => {
			if (item.documentType === 'isFrontPage') setIsFrontPage(true);
		});
	}, [pageContext.url]);
	
	return (
		<Header
			logo={pageLogo ? pageLogo : siteLogo}
			logoLink={siteLogoLink}
			logoSize={siteLogoSize}
			subsiteBar={
				<SubsiteBar
					subsiteBarLogo={subsiteBarLogo}
					subsiteBarLogoLink={subsiteBarLogoLink}
					hideSubsiteBar={subsiteBar === false ? true : false}
				/>
			}
			searchPage={searchPage}
			mobileNavigation={
				<MobileNavigation
					searchPage={searchPage}
					globalNavigation={navigationItems}
					hideServiceNavigation={hideServiceNavigation}
					serviceNavigationItems={serviceNavigationItems}
					currentPageId={pageContext.id}
					languageSelector={
						pageContext._pageContent.page.languagePicker?.enabled
							?  pageContext._pageContent.page.languagePicker?.languages
							: siteContext.externalLanguagePicker?.languages
					}
					currentLanguage={
						pageContext._pageContent.page.languagePicker?.enabled
							?  pageContext._pageContent.page.languagePicker.currentLanguage
							: siteContext.externalLanguagePicker.currentLanguage
					}
					isFrontPage={isFrontPage}
					isInternalLanguageSelector={siteContext.settings.internalLanguagePicker.enabled}
				/>
			}
			serviceNavigation={
				<ServiceNavigation
					hideServiceNavigation={hideServiceNavigation}
					serviceNavigationItems={serviceNavigationItems}
					isFrontPage={isFrontPage}
				/>
			}
			navigation={<Navigation navigationItems={navigationItems} style="primary" isFrontPage={isFrontPage} />}
			languageSelector={
				<LanguageSelector
					languages={
						pageContext._pageContent.page.languagePicker?.enabled
							? pageContext._pageContent.page.languagePicker?.languages
							: siteContext.externalLanguagePicker?.languages
					}
					currentLanguage={
						pageContext._pageContent.page.languagePicker?.enabled
							?  pageContext._pageContent.page.languagePicker.currentLanguage
							: siteContext.externalLanguagePicker.currentLanguage
					}
					isFrontPage={isFrontPage}
				/>
			}
			isFrontPage={isFrontPage}
		/>
	);
};
