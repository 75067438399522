import React from 'react';
import { RouteContextProvider } from 'ui/contexts/RouteContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Layout = ({ children, router }) => {
	return (
		<RouteContextProvider router={router}>
			{children}
		</RouteContextProvider>
	);
};