import { GridCell, ContentSection } from 'ui/components';
import { ModuleFeature } from 'features/Modules';

export const ContentSectionFeature: React.FC<Content.ContentSection> = ({ content, settings }) => {
	const { content: contentModules, title } = content?.properties ?? {};
	const { centered, width } = settings?.properties ?? {};

	return (
		<ContentSection centered={centered} width={width} title={title}>
			{contentModules.map((contentModule, index) => {
				if (
					contentModule?.settings &&
					'desktopWidth' in contentModule?.settings?.properties &&
					'mobileWidth' in contentModule?.settings?.properties
				) {
					const { desktopWidth, mobileWidth } = contentModule?.settings?.properties ?? {};

					return (
						<GridCell key={index} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
							<ModuleFeature contentModule={contentModule} />
						</GridCell>
					);
				}

				return <ModuleFeature contentModule={contentModule} key={index} />;
			})}
		</ContentSection>
	);
};
