import { useCallback, useEffect, useState } from 'react';
import { DocumentList } from 'ui/components/3-organisms/DocumentList';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import getConfig from 'next/config';
const {publicRuntimeConfig} = getConfig()

export const DocumentListFeature: React.FC<Content.DocumentList> = ({ content }) => {
	const { header, teaser, documents } = content.properties;
	const [mediaDocuments, setMediaDocuments] = useState([]);
	const getAllDocumentsFromFolderId = useCallback(() => {
		if (documents?.id) {
			axios({
				url: `${publicRuntimeConfig.NEXT_PUBLIC_API_URL}api/media/${documents.id}`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					hostname: `${publicRuntimeConfig.NEXT_PUBLIC_LOCAL_HOSTNAME_URL}`,
				},
			}).then((res) => {
				const mapped: Content.MediaFile[] = res.data.map((doc) => {
					const data: Content.MediaFile = {
						id: doc.id,
						name: doc.name,
						url: publicRuntimeConfig.NEXT_PUBLIC_API_URL + doc.url,
						extension: doc.extension,
					};
					return data;
				});
				setMediaDocuments(mapped);
			});
		}
	}, [documents]);
	useEffect(() => {
		getAllDocumentsFromFolderId();
	}, [getAllDocumentsFromFolderId, documents]);

	return documents?.id ? <DocumentList documents={mediaDocuments} header={header} teaser={teaser} /> : <></>;
};
