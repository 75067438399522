import { AgendaList } from 'ui/components';
import { agendaFetcher } from 'features/Modules/AgendaList/AgendaFetcher';
import { getAllAgendasByType, getAllAgendas, getAllAgendaById } from 'application/repositories/AgendaRepository';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
export const AgendaListFeature: React.FC<Content.AgendaList> = ({ content }) => {
	const [agendaId, setAgendaId] = useState<string>('');
	const { agendaPage} = content.properties;
	const agenda = useSWR({
		query: agendaId
	}, getAllAgendaById)

	useEffect(() => {
	}, [agendaId]);
	return (
	<AgendaList agendaPage={agendaPage} {...content?.properties} filters={[{filterName:"agenda", options:[{text:"Referat", value:"summary"}, {text:"dagsorden", value:"agenda"}, {text:"Kommende Møde",value:"UpcomingMeeting"}], }]} agendaFetcher={agendaFetcher}/>
);}
