import Head from 'next/head';

export interface OpenGraphProps {
	title: string;
	description: string;
	image: Content.Image;
	imageText: string;
	pageName: string;
	pageUrl: string;
}

export const OpenGraph: React.FC<OpenGraphProps> = (props) => {
	const siteName = process.env.NEXT_PUBLIC_SITENAME;

	return (
		<Head>
			<meta property="og:url" content={props.pageUrl} />
			<meta property="og:type" content="website" />
			<meta property="og:title" content={props.title ? props.title : props.pageName} />
			<meta property="og:description" content={props.description} />
			{props.image && <meta property="og:image" content={props.image.url} />}
			<meta name="og:image:alt" content={props.imageText} />
			{siteName && <meta property="og:site_name" content={siteName} />}
			<meta name="twitter:card" content="summary_large_image" />
		</Head>
	);
};
