import { FindWay } from 'ui/components';

export const FindWayFeature: React.FC<Content.FindWay> = ({ content, settings }) => {
	const { findWayItem } = content?.properties ?? {};
	const findWayItemsMapped = findWayItem.map(({ content }) => {
		const { shortcuts, text } = content?.properties ?? {};

		return {
			shortcuts,
			text,
		};
	});

	return <FindWay {...content?.properties} findWayItem={findWayItemsMapped} {...settings?.properties} />;
};
