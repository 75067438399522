import { createLogger, transports, format } from 'winston';
// We must import setImmediate as it is used by Winston
// https://github.com/theY4Kman/dcrf-client/issues/27
import 'setimmediate';
import DailyRotateFile from 'winston-daily-rotate-file';
import BrowserConsole from './_loggerBrowserConsole';
import { LOG_TO_DISK, NEXT_PUBLIC_LOG_TO_CONSOLE, NODE_ENV } from '_constants';

// Azure Application Insights automatically implements Console from Winston logger
// so we don't need to manually use eg. winston-azure-application-insights transport
// https://github.com/Microsoft/ApplicationInsights-node.js#automatic-third-party-instrumentation

const serverside = typeof window === 'undefined';
const development = NODE_ENV === 'development';

const logger = createLogger({
	exitOnError: true,
	level: 'info',
	format: format.combine(
		format.timestamp({
			format: 'YYYY-MM-DD HH:mm:ss',
		}),
		format.errors({ stack: true }),
		format.splat(),
		format.json(),
	),
	defaultMeta: { app: 'website' },
	transports: [
		// Default transport must be present
		// to avoid warnings
		new transports.Console({
			handleExceptions: true,
			silent: true,
		}),
	],
});

//
// If NEXT_PUBLIC_LOG_TO_CONSOLE is enabled
// then write logs to console and browser console
//
let browserConsoleTransport: BrowserConsole;

if (NEXT_PUBLIC_LOG_TO_CONSOLE) {
	browserConsoleTransport = new BrowserConsole({
		handleExceptions: true,
		silent: false,
		format: format.simple(),
	});

	logger.add(browserConsoleTransport);
}

//
// If LOG_TO_DISK is enabled and we're on the
// server then write logs to disk
//
let dailyRotateTransport: DailyRotateFile;

if (LOG_TO_DISK && serverside) {
	dailyRotateTransport = new DailyRotateFile({
		handleExceptions: true,
		silent: false,
		filename: './logs/application-%DATE%.log',
		datePattern: 'YYYY-MM-DD-HH',
		zippedArchive: true,
		maxSize: '20m',
		maxFiles: '14d',
	});

	logger.add(dailyRotateTransport);
}

if (development) {
	logger.info(
		`LOGGER is in DEVELOPMENT MODE,` +
			`\nYour logs may turn up multiple times. This is because React Strict Mode renders twice when in development mode`,
	);
}

export default logger;