import * as winston from 'winston';
import TransportStream from 'winston-transport';

export default class BrowserConsole extends TransportStream {
	private methods = {
		debug: 'debug',
		error: 'error',
		info: 'info',
		warn: 'warn',
	};

	constructor(opts?: TransportStream.TransportStreamOptions) {
		super(opts);

		// eslint-disable-next-line no-prototype-builtins
		if (opts && opts.level && Level.hasOwnProperty(opts.level)) {
			this.level = opts.level;
		}
	}

	public log(logEntry: winston.LogEntry, next: () => void): void {
		setImmediate(() => {
			this.emit('logged', logEntry);
		});

		const { message, level } = logEntry;
		const mappedMethod = this.methods[level];

		console[mappedMethod](`${level}: ${message}`, Object.fromEntries(Object.entries(logEntry)));
		next();
	}
}

enum Level {
	error = 0,
	warn = 1,
	info = 2,
	debug = 4,
}
