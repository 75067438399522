import { ArticleHeader } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const ArticleHeaderFeature: React.FC<Content.ArticleHeader> = ({ content }) => {
    const dictionary = useDictionaryContext();
    const anchorLinkText =  dictionary.getValue('Contact.AnchorLinkText', null, 'Se kontaktoplysninger');
    return (
        <ArticleHeader {...content?.properties} anchorLinkText={anchorLinkText} className="" />
    )
};
