import { CludoFindDirection } from 'ui/components';
import { useSiteContext } from 'application/contexts/SiteContext';

export const CludoFindDirectionFeature: React.FC<Content.CludoFindDirection> = ({ content }) => {
	const siteContext = useSiteContext();
    const cludoEngineId = siteContext.cludoEngineId;
	
	 const filterItem = content.properties?.filterValue?.properties
	const filterValue = filterItem == undefined ? "" : filterItem?.filterValue;
	


    return (<CludoFindDirection {...content?.properties}
			filterValue={filterValue}
			cludoEngineId={cludoEngineId}
		 	image={{
			altText: content?.properties?.image?.altText,
			url: content?.properties?.image?.url,
			focalPoint: content?.properties?.image?.focalPoint,
		}} />)
};
