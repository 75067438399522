import { Event } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';

export const EventFeature: React.FC<Content.Event> = ({ content }) => {
	const dictionary = useDictionaryContext();
	
	return (
		<Event
			{...content?.properties}
			dateLabel={dictionary.getValue('Event.date', null, 'Dato')}
			timeLabel={dictionary.getValue('Event.time', null, 'Tid')}
			timePrefix={dictionary.getValue('Event.timePrefix', null, 'Klokken')}
			locationLabel={dictionary.getValue('Event.location', null, 'Sted')}
			priceLabel={dictionary.getValue('Event.price', null, 'Pris')}
		/>
	);
};
