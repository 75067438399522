import { ImageSlider } from 'ui/components';

export const ImageSliderFeature: React.FC<Content.ImageSlider> = ({ content }) => {

  const images = content?.properties?.images;
  const removeAlt = content?.properties?.removeAlt;
  const isAutoChangeEnabled = content?.properties.isAutoChangeEnabled;


  const mappedImages = images?.map(({content}) => {
    const {image, imageDescription} = content.properties;
    return {
      image,
      imageDescription
    }
  })
  
  return (
    <ImageSlider
      images={mappedImages}
      removeAlt={removeAlt}
      isAutoChangeEnabled={isAutoChangeEnabled}
    />
  );

}



