import { getSortedEventsFromCms } from "application/adapters/cms/eventAdapter";

export interface GetPageContentProps {
	url: string;
	host: string | string[];
	preview?: boolean;
	previewData?: Models.PreviewData;
	accessToken?: string;
	locale?:string;
}

export interface EventListQuery {
	host?: string;
	pageSize?: number;
	pageNumber?: number;
	searchableLocations?: string[];
	searchableTopics?: string[];
}

export const getEventsByFilters = async (query: any): Promise<Models.EventList> => {
	return getSortedEventsFromCms(query.host, query.pageSize, query.pageNumber,query.locale,  query.searchableLocations, query.searchableTopics);
};