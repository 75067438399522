import { PrioritizedContent } from 'ui/components';

export const PrioritizedContentFeature: React.FC<Content.PrioritizedContent> = ({ content }) => {
	const { contentCards, heading, seeAllLink } = content?.properties ?? {};
	const mappedContentCards = contentCards.map(({ content }) => {
		const { image, heading, multiLink, richText, singleLink, borderColor } = content?.properties ?? {};

		return {
			image,
			heading,
			multiLink,
			richText,
			singleLink,
			borderColor,
		};
	});

	return <PrioritizedContent heading={heading} seeAllLink={seeAllLink} contentCards={mappedContentCards}/>;
};
