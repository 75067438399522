import { Container, Main } from 'ui/components';
import { ModuleFeature } from 'features/Modules';
import { NEXT_PUBLIC_COOKIEBOT_ID } from 'application/adapters/cms/_constants';
import { useEffect, useRef } from 'react';

export const CookieDeclarationPageFeature: React.FC<Content.CookieDeclarationPage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};
	const declarationRef = useRef<HTMLDivElement | null>(null);
	const declarationScript = `
	<script id="CookieDeclaration" 
	src="https://consent.cookiebot.com/${NEXT_PUBLIC_COOKIEBOT_ID}/cd.js" 
	type="text/javascript" async>
	</script>`
	useEffect(() => {
		const fragment = document.createRange().createContextualFragment(declarationScript);
		declarationRef?.current?.replaceChildren(fragment);
	  }, [declarationScript, declarationRef]);
	return (
		<Container width='Standard'>
		<Main
			header={pageHeader.map((headerModule, index) => (
				<ModuleFeature key={index} contentModule={headerModule} />
			))}
		>
			{pageSections.map((sectionModule, index) => (
				<ModuleFeature key={index} contentModule={sectionModule} />
			))
			}
			<div ref={declarationRef}></div>
		</Main>
		</Container>
	);
};
