import { Footer } from 'ui/components';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { useEffect } from 'react';
//import { getSite } from 'application/repositories/siteRepository';

export const FooterFeature: React.FC = () => {
	const navContext = useNavigationContext();
	const siteContext = useSiteContext();
	
	const logo = siteContext.settings?.footer?.logo;
	const siteLogoLink = siteContext.logoLink;
	const siteLogoSize = siteContext.logoSize;
	const hideFooterNavigation = siteContext.hideFooterNavigation;

	const serviceNavigationItems = navContext?.getNavigation({
		byName: 'servicemenu',
	});
	const footerNavigation = navContext?.getNavigation({ includeRootItem: false });
	
	let { columnOneHeading, columnOneCustomLink, columnTwoContent, socialLinksHeading, metaLinks } = {columnOneHeading:null, columnOneCustomLink:null, columnTwoContent:null, socialLinksHeading:null, metaLinks:null};
	if(siteContext.settings?.footer){
		columnOneHeading = siteContext.settings.footer.columnOneHeading;
		columnOneCustomLink = siteContext.settings.footer.columnOneCustomLink;
		columnTwoContent = siteContext.settings.footer.columnTwoContent;
		socialLinksHeading = siteContext.settings.footer.socialLinksHeading;
		metaLinks = siteContext.settings.footer.metaLinks;
	}
	const socialLinks = siteContext.settings.socialLinks;
	return (
		<Footer
			footerNavigation={footerNavigation}
			hideFooterNavigation={hideFooterNavigation}
			serviceNavigationItems={serviceNavigationItems}
			columnOneHeading={columnOneHeading}
			columnOneCustomLink={columnOneCustomLink}
			columnTwoContent={columnTwoContent}
			socialLinksHeading={socialLinksHeading}
			socialLinks={socialLinks}
			logo={logo && logo}
			logoLink={siteLogoLink}
			logoSize={siteLogoSize}
			metaLinks={metaLinks}
		></Footer>
	);
};
