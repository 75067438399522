import { Main, Container, Event } from 'ui/components';
import { ModuleFeature } from 'features/Modules';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';

export const EventPageFeature: React.FC<Content.EventPage> = ({ properties }) => {
	const dictionary = useDictionaryContext();
	const pageContext = usePageContext();

	const { pageHeader, pageSections } = properties ?? {};
	return (
		<Main>
			<Container width="Small">
				<Event
					{...properties}
					dateLabel={dictionary.getValue('Event.date', null, 'Dato')}
					timeLabel={dictionary.getValue('Event.time', null, 'Tid')}
					timePrefix={dictionary.getValue('Event.timePrefix', null, 'Klokken')}
					locationLabel={dictionary.getValue('Event.location', null, 'Sted')}
					priceLabel={dictionary.getValue('Event.price', null, 'Pris')}
				/>
			</Container>
			{pageSections.map((pageSection, index) => (
				<ModuleFeature key={index} contentModule={pageSection} />
			))}
		</Main>
	);
};
