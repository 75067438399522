import { Container, Main } from 'ui/components';
import { ModuleFeature, NavigationLeftFeature } from 'features/Modules';
import { usePageContext } from 'application/contexts/PageContext';
import { useState, useEffect } from 'react';

export const ContentPageFeature: React.FC<Content.ContentPage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};
	const pageContext = usePageContext();
	const navLeftComponent = pageContext?.content?.pageSettings.find((item) => item.documentType === 'navigationLeft');

	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<ModuleFeature key={index} contentModule={headerModule} />
			))}
		>
			
			{navLeftComponent ? (
				<>
					<Container width="Standard">
						<div className="u-with-leftnavigation-container">
							<NavigationLeftFeature />
							<div className="u-with-leftnavigation-content">
								{pageSections.map((pageSection, index) => (
									<ModuleFeature key={index} contentModule={pageSection} />
								))}
							</div>
						</div>
					</Container>
				</>
			) : (
				<>
						{pageSections.map((pageSection, index) => (
							<ModuleFeature key={index} contentModule={pageSection} />
						))}
				</>
			)}
		</Main>
	);
};