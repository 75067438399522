import {getAgendasFromApi,getAgendaByIdFromApi} from 'application/adapters/cms/agendaAdapter';

export interface AgendaQuery {
	host?: string;
	rootId?: number;
	pageSize?: number;
	pageNumber?: number;
	types?:string[];
}
export interface SingleAgendaQuery {
	host?: string;
	guid?: string;
}

export const getAllAgendas = async (query: AgendaQuery): Promise<Models.AgendaList> => {
	//Client side
	return await getAgendasFromApi(query.pageSize, query.pageNumber);
};
export const getAllAgendasByType = async (query: AgendaQuery): Promise<Models.AgendaList> => {
	//Client side
	return await getAgendasFromApi(query.pageSize, query.pageNumber,query.types);
}
export const getAllAgendaById = async (query: SingleAgendaQuery): Promise<Models.Agenda> => {
	//Client side
	return await getAgendaByIdFromApi(query.guid);
}
export const getAgendaByIdFromRepository = async (
	guid?: string,
): Promise<Models.Agenda> => {
	return await getAllAgendaById({guid});

};
