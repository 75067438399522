import { UrgentContact } from 'ui/components';
import { PageContext } from 'application/contexts/PageContext';
import { useContext } from 'react';
export const UrgentContactFeature: React.FC<Content.UrgentContact> = ({ content }) => {
	const { heading, headingColor, backgroundColor, link, urgentContactItems } = content?.properties ?? {};
	return (
		<UrgentContact
			heading={heading}
			headingColor={headingColor}
			backgroundColor={backgroundColor}
			link={link}
			urgentContactItems={urgentContactItems.map(({ content }) => {
				const { number, text } = content?.properties ?? {};

				return {
					number,
					text,
				};
			})}
		/>
	);
};
