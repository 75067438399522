import { getSortedNewsFromCmsNew } from 'application/adapters/cms/newsAdapter';
import { useLocaleContext } from 'application/contexts/LocaleContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { NewsList } from 'ui/components';
export const NewsListFeature: React.FC<Content.NewsList> = ({ content }) =>{
	const [pageNumber,setPageNumber] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [limit, setLimit] = useState(content?.properties?.count);
	const [offset, setOffset] = useState(0);
	const locationFilter = content?.properties?.locationFilter?.map(location => location.id);
	const topicFilter = content?.properties?.topicFilter?.map(topic => topic.id);
	const context = usePageContext();
	const {currentLocale} = useLocaleContext();
interface INewsDataItem {
	id:string;
	name:string;
	url:string;
	properties:{
		newsHeading: string;
		newsTeaser: string;
		newsKicker: string;
		newsDate: string;
		newsDateFormatted: string;
	}	
		newsHeading: string;
		newsTeaser: string;
		newsKicker: string;
		newsDate: string;
		newsDateFormatted: string;
}


	const {data} = useSWR({
		host: null,
		limit: limit,
		offset: offset,
		searchableLocations:locationFilter,
		searchableTopics: topicFilter,
		preview: context.preview,
		previewData: context.previewData,
		locale:currentLocale
	},
	getSortedNewsFromCmsNew,{ revalidateOnFocus: false }
	)	
	const [news, setNews] = useState([]);
	const onPageClickHandler = (event: {
		index: number | null;
		selected: number;
		nextSelectedPage: number | undefined;
		event: object;
		isPrevious: boolean;
		isNext: boolean;
		isBreak: boolean;
		isActive: boolean;
	  }) => {
		setPageNumber(event.selected);
		setOffset(event.selected * limit);
	};

	useEffect(()=>{
		if (data) {
			setTotalPages(data.totalPages);
			const mappedResults = data.news.map((result:INewsDataItem) => {
				return {
					heading: result.properties.newsHeading,
					link: {
						url: result.url,
					},
					kicker: result.properties.newsKicker,
					text: result.properties.newsTeaser,
					date: result.properties.newsDateFormatted,
				};
			});
			setNews(mappedResults);
		}
		},[data]);

	return(
	<NewsList pageNumber={pageNumber} {...content?.properties} articles={news} loadingStatus={ data ? false : true} 
	limit={limit} totalPages={totalPages} setOffset={setOffset} onPageClickHandler={onPageClickHandler} />
)};
