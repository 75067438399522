import { VideoListTags } from 'ui/components';
import { postVideoListTags } from 'application/repositories/videoListTagsRepository';

export const VideoListTagsFeature: React.FC<Content.VideoListTags> = ({ content }) => {
	const { heading, tags } = content?.properties ?? {};

	// const videosMapped = video.map((item) => {
	// 	const { videoEmbed, videoTitle} = item?.properties ?? {};

	// 	return {  heading: videoTitle};
	// });
	if(!tags) return null

	return <VideoListTags heading={heading} tags={tags} postVideoListTags={postVideoListTags} />;
};
