import { ImageGrid } from 'ui/components';

export const ImageGridFeature: React.FC<Content.ImageGrid> = ({ content, settings }) => {
    const { list } = content?.properties ?? {};
    const mappedImageWithTitle = list.map(({content}) => {
        const { title, link, image, intro, numberOfColumns} = content?.properties ?? {};
        return {
            title, 
            link, 
            image,
            intro,
            numberOfColumns
        }
    });
    return <ImageGrid list={mappedImageWithTitle} numberOfColumns={settings.properties.numberOfColumns}/>;
}; 