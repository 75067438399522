import { Main, Container, ArticleHeader, VideoCard, ArticleDate, VideoConsent } from 'ui/components';
import { ModuleFeature } from 'features/Modules';
import { NavigationLeftFeature } from 'features/Modules/NavigationLeft';
import { usePageContext } from 'application/contexts/PageContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useEffect, useState } from 'react';
import { IsLaterThanNumberOfDays } from 'helpers/timeManipulation'

export const ArticlePageFeature: React.FC<Content.ArticlePage> = ({ properties }) => {
	const { pageSections, pageSections2, videoEmbed } = properties ?? {};
	const pageContext = usePageContext();
	const pageDate = pageContext._pageContent.page.publishDate;
	const navLeftComponent = pageContext?.content?.pageSettings.find((item) => item.documentType === 'navigationLeft');

	const dictionary = useDictionaryContext();
	const anchorLinkText = dictionary.getValue('Contact.AnchorLinkText', null, 'Se kontaktoplysninger');
	const dateText = dictionary.getValue('Article.LastUpdate', null, 'Opdateret');
	const langFormat = dictionary.getValue('Article.LangSpecificDateFormatter', null, 'da-DK');

	const dateFormattede = new Date(pageDate);
	const dateOptions: Intl.DateTimeFormatOptions = {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	};

	const strDate = dateFormattede.toLocaleString(langFormat, dateOptions);
	const strDateWithText = `${dateText} ${strDate}`;

	return (
		<Main>
			{navLeftComponent ? (
				<>
					<Container width="Standard">
						<div className="u-with-leftnavigation-container">
							<NavigationLeftFeature />
							<div className="u-with-leftnavigation-content">
								<Container width="Standard">
									<ArticleHeader anchorLinkText={anchorLinkText} {...properties} />
								</Container>
								{videoEmbed && <VideoConsent video={videoEmbed} />}
								{pageSections.map((pageSection, index) => (
									<ModuleFeature key={index} contentModule={pageSection} />
								))}
							</div>
						</div>
					</Container>
				</>
			) : (
				<>
					<Container width="Standard" className='u-no-leftnavigation-container'>
						<ArticleHeader anchorLinkText={anchorLinkText} {...properties} />
						{videoEmbed && <VideoConsent video={videoEmbed} />}
						{pageSections.map((pageSection, index) => (
							<ModuleFeature key={index} contentModule={pageSection} />
						))}
						
					</Container>
				</>
			)}

			{pageSections2.map((pageSection, index) => (
				<ModuleFeature key={index} contentModule={pageSection} />
			))}
			<Container width='Standard'>
				<ArticleDate old={IsLaterThanNumberOfDays(new Date(langFormat), 90)} dateAndText={strDateWithText} />
			</Container>
		</Main>
	);
};
