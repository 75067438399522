import { TopTasks } from 'ui/components';

export const TopTasksFeature: React.FC<Content.TopTasks> = ({ content, settings }) => {

	const { heading, topTasksItem } = content?.properties ?? {};
	const topTasksItemsMapped = topTasksItem.map(({ content }) => {
		const { shortcuts, text } = content?.properties ?? {};

		return {
			shortcuts,
			text,
		};
	});

	return <TopTasks heading={heading} topTasksItem={topTasksItemsMapped} {...settings?.properties} />;
};
