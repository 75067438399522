import { ArticleByline, ArticleDate, ArticleHeader, Container, Linkbox, Main, Spinner } from 'ui/components';
import { ModuleFeature, NavigationLeftFeature } from 'features/Modules';
import { usePageContext } from 'application/contexts/PageContext';
import { useState, useEffect } from 'react';
import { getAgendaByIdFromRepository } from 'application/repositories/AgendaRepository';
import useSWR from 'swr';
import { useRouter } from 'next/router';;
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { AgendaLinkbox } from 'ui/components/3-organisms/AgendaLinkbox';
import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig()

export interface LinkBoxItem {
	 text: string; 
	 link: Content.Link;
	};
export const AgendaPageFeature: React.FC<Content.AgendaPage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};
	const pageContext = usePageContext();
	const router = useRouter();
	const agendaItemId = router.query.agendaId as string;
	const APIURL = publicRuntimeConfig.NEXT_PUBLIC_AGENDA_API_URL +"/api/v1/Document/Get/" ;
	const [agendaId, setAgendaId] = useState<string>(agendaItemId);
	const agendaItem = useSWR({ guid: agendaId }, getAgendaByIdFromRepository);
	const [agendaItems, setAgendaItems] = useState<Models.AgendaItem[]>();
	const [agendaItemDocuments, setAgendaItemDocuments] = useState<LinkBoxItem[]>();
	const [agendaItemPresentations, setAgendaItemPresentations] = useState<LinkBoxItem[]>();
	const dictionary = useDictionaryContext();
	const getDocuments = () => {
		const _documents: Models.AgendaFileItem[] = [];
		agendaItem.data?.items?.map((item) => {
			item.documents?.map((document) => {
				if (document.documentUid === null || document.documentUid === '00000000-0000-0000-0000-000000000000') {
					return;
				}
				const _document= document;
				_document.FileUrl = APIURL + _document.documentUid;
				_documents.push(_document);
			});
	
		});
		const __documentstoLinkbox:LinkBoxItem[] = [];
		_documents.map((document) => {
			const _document:LinkBoxItem = {
				text: document.title,
				link: {
					name: document.title,
					url: document.FileUrl,
					target: '_blank',
				},
			};
			__documentstoLinkbox.push(_document);
		});
		setAgendaItemDocuments(__documentstoLinkbox);
	};
	const getPresentations = () => {
		const _presentations: Models.AgendaFileItem[] = [];
		agendaItem.data?.items?.map((item) => {
			item.presentations?.map((presentation) => {
				if (presentation.documentUid === null || presentation.documentUid === '00000000-0000-0000-0000-000000000000') {
					return;
				}
				const _presentation = presentation;
				_presentation.FileUrl = APIURL + presentation.documentUid;
				_presentations.push(_presentation);
			});
	
		});
		const _presentationstoLinkbox:LinkBoxItem[] = [];
		_presentations.map((presentation) => {
			const _presentation:LinkBoxItem = {
				text: presentation.title,
				link: {
					name: presentation.title,
					url: presentation.FileUrl,
					target: '_blank',
				},
			};
			_presentationstoLinkbox.push(_presentation);
		});
		setAgendaItemPresentations(_presentationstoLinkbox);
	};
	const navLeftComponent = pageContext?.content?.pageSettings.find((item) => item.documentType === 'navigationLeft');
	useEffect(() => {
		
		setAgendaItems(agendaItem.data?.items);
		getDocuments();
		getPresentations();
	
	}, [agendaItem.data?.items]);
	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<ModuleFeature key={index} contentModule={headerModule} />
			))}
		>
			{navLeftComponent ? (
				<>
					<Container width="Standard">
						<div className="u-with-leftnavigation-container">
							<NavigationLeftFeature />
							<div className="u-with-leftnavigation-content">
								{pageSections.map((pageSection, index) => (
									<ModuleFeature key={index} contentModule={pageSection} />
								))}
							</div>
						</div>
					</Container>
				</>
			) : (
				<>
					{pageSections.map((pageSection, index) => (
						<ModuleFeature key={index} contentModule={pageSection} />
					))}
				</>
			)}
			{agendaItem.data ? (
				<>
			<Container width="Standard">
				<ArticleHeader
					heading={agendaItem.data?.name}
					teaser={`${dictionary.getValue('Article.Mødested', null, 'Mødested')}: ${agendaItem.data?.meetingLocation}`}
				/>
				
				<ArticleDate dateAndText={dictionary.getValue('Article.Mødestart', null, 'MødeStart') } date={agendaItem.data?.meetingBeginFromUtc} />
				<ArticleDate dateAndText={dictionary.getValue('Article.Mødeslut', null, 'Mødeslut')} date={agendaItem.data?.meetingEndUtc} />
				<ArticleByline
					fullName={`${dictionary.getValue('Article.ByLineComitee', null, 'Komitee')}: ${agendaItem.data?.committeeName}`}
					workTitle={`Agenda label:  ${agendaItem.data?.agendaTypeLabel}`}
				/>
			</Container>
		
				{agendaItems ? (
					<>
						<AgendaLinkbox linkboxItems={agendaItemDocuments} heading={dictionary.getValue('Article.Dokumenter', null, 'Dokumenter')}/>
						<AgendaLinkbox linkboxItems={agendaItemPresentations} heading={dictionary.getValue('Article.Presentations', null, 'Presentations')}/>
						
					</>
				) : (
					<p>No agenda items</p>
				)}
				</>
				):(<Container width='Small'><Spinner/></Container>)}
		</Main>
	);
};
