import { VideoList } from 'ui/components';

export const VideoListFeature: React.FC<Content.VideoList> = ({ content }) => {
	const { video, description, moduleMargin  } = content?.properties ?? {};

	const videosMapped = video ? video.map((item) => {
		const { videoEmbed, videoTitle} = item?.properties ?? {};

		return { embedLink: videoEmbed, heading: videoTitle};
	}) : {};


	return <VideoList videos={videosMapped} listDescription={description} moduleMargin={moduleMargin}  />;
};
