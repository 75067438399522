const CONTENT_API_URL = process.env.CONTENT_API_URL
	? process.env.CONTENT_API_URL?.endsWith('/')
		? process.env.CONTENT_API_URL
		: `${process.env.CONTENT_API_URL}/`
	: undefined;

const NEXT_PUBLIC_AGENDA_API_URL = process.env.NEXT_PUBLIC_AGENDA_API_URL;
const DOMAIN_PROTOCOL = process.env.DOMAIN_PROTOCOL ?? 'https';
const DOMAIN_PORT = process.env.DOMAIN_PORT;
const NEXT_PUBLIC_LOCAL_HOSTNAME_URL = process.env.NEXT_PUBLIC_LOCAL_HOSTNAME;
const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL;
const NEXT_PUBLIC_COOKIEBOT_ID = process.env.NEXT_PUBLIC_COOKIEBOT_ID;
const NEXT_PUBLIC_SITEIMPROVE_ID = process.env.NEXT_PUBLIC_SITEIMPROVE_ID;
export {
	CONTENT_API_URL,
	DOMAIN_PROTOCOL,
	DOMAIN_PORT,
	NEXT_PUBLIC_AGENDA_API_URL,
	NEXT_PUBLIC_LOCAL_HOSTNAME_URL,
	NEXT_PUBLIC_API_URL,
	NEXT_PUBLIC_COOKIEBOT_ID,
	NEXT_PUBLIC_SITEIMPROVE_ID,
};
