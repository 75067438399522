import { LinkboxAutomatic } from 'ui/components';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useEffect, useState } from 'react';

export const LinkboxAutomaticFeature: React.FC<Content.LinkboxAutomatic> = ({ content, settings }) => {
	const { heading, backgroundColor } = content?.properties ?? {};
	const { ignoreHideNavigation } = settings?.properties ?? {};

	let navItems = [];

	const navContext = useNavigationContext();
	const pageContext = usePageContext();
	

	const navigationItems = navContext?.getNavigation({ 
		byUrl: pageContext.url,
		includeHidden: ignoreHideNavigation ? true : false,
	});

	if(navigationItems && navigationItems.length > 0){
		navigationItems?.map((navigationItem) => {
			navItems.push(navigationItem)
		});
	}

	return (
		<LinkboxAutomatic
			{...content?.properties}
			heading={heading}
			linkboxItems={navItems.map((content) => {
				const { name, url } = content ?? {};
				const text = name;

				return { text, link: { name, url: url } };
			})}
			backgroundColor={backgroundColor}
		/>
	);
};
