import { axiosInstance as axios } from 'helpers/axiosInstance';
import { useState } from 'react';
//import { NEXT_PUBLIC_API_URL } from './_constants';
import getConfig from 'next/config';
const {publicRuntimeConfig} = getConfig()

export const postFormData: (formId: string, body: any) => Promise<Models.Product> = async (formId, body) => {
	const method = 'POST';
	const headers = {
		'Content-Type': 'application/json',
	};
	const formApiUrl = `${publicRuntimeConfig.NEXT_PUBLIC_API_URL}api/form/`;
	const endpointUrl = `${formApiUrl}${formId}`;

	return axios({
		url: endpointUrl,
		method: method,
		headers,
		data: body,
	}).then((res) => {
		const { data } = res;
		if (!data) {
			return null;
		}

		return data;
	});
};
