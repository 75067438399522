import { useNavigationContext } from 'application/contexts/NavigationContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useEffect, useRef, useState } from 'react';
import { NavigationLeft } from 'ui/components';

export const NavigationLeftFeature: React.FC<Navigation.NavigationItem> = () => {
	const [siteName, setSiteName] = useState<string>('');
	const [currentPageNavItem, setCurrentPageNavItem] = useState<Navigation.NavigationItem>({});
	const [parentNavItem, setParentNavItem] = useState<Navigation.NavigationItem>({});
	const navContext = useNavigationContext();
	const pageContext = usePageContext();
	const parent = useRef<Navigation.NavigationItem>();
	const navigationItemsData = navContext?.getNavigation({
		currentUrl: pageContext.url,
		includeHidden: true,

	});
	const setParentSitename = (item: Navigation.NavigationItem) => {
		if (!parentNavItem.name) {
			setSiteName(item.name);
		} else {
			setSiteName(parentNavItem.name);
		}
	};
	const getCurrentNavItems = (navItems: Navigation.NavigationItem[]) => {
		navItems?.map((item) => {
			if (item.active) {
				if (item.current) {
					setCurrentPageNavItem(item);
				} else {
					setParentNavItem(item);
					getCurrentNavItems(item.descendants);
					setSiteName(parentNavItem.name);
				}
			}
		});
	};

	useEffect(() => {
		getCurrentNavItems(navigationItemsData);
	}, [pageContext.url]);
	useEffect(() => {
		setParentSitename(currentPageNavItem);
	}, [currentPageNavItem]);
	if (!currentPageNavItem?.descendants) return null;
	return <NavigationLeft heading={siteName} parentgNavItem={parentNavItem} currentNavItem={currentPageNavItem} />;
};
