import { useSiteContext } from 'application/contexts/SiteContext';
import { Map } from 'ui/components';
import logger from 'helpers/logger';

export const MapFeature: React.FC<Content.Map> = ({ content, settings }) => {
	const { mapMarkers } = content?.properties ?? {};

	const siteSettings = useSiteContext();
	const { apiKey } = siteSettings?.settings.maps ?? {};

	if (!apiKey) {
		logger.error('[MapFeature] Maps API key is not set');
		return null;
	}

	const mappedMappedMarkers: Models.MapsMarker[] = mapMarkers.map((marker) => ({ ...marker?.content?.properties }));

	return <Map mapMarkers={mappedMappedMarkers} apiKey={apiKey} {...settings?.properties} />;
};
